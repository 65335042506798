import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import Layout from '../components/Layout';
import '../styles/racqy-app.css';
import GetLocation from '../hooks/getLocation';
import { navigate } from 'gatsby';

import moment from 'moment';
import {
  getSportHeaderImage,
  getLevelColor,
  getSportsName,
} from '../helper/racqyHelper';

import exitIcon from '../../src/assets/android/icons/share-icon.png';
import calendarIconPng from '../../src/assets/android/icons/calendar-icon.png';
import locationIconPng from '../../src/assets/android/icons/location-icon.png';
import ticketIconPng from '../../src/assets/android/icons/ticket.png';
import difficultyIconPng from '../../src/assets/android/icons/difficulty-icon.png';
import hostIcon from '../../src/assets/icons/host.svg';
import profileIconPng from '../../src/assets/icons/profile-icon.svg';
import clubOutlineIconPng from '../../src/assets/android/icons/club-outline.png';
import InfinityImage from '../../src/assets/Infinity.svg';
import { showSuccessAlert } from '../utils/toastMessages';
import { client } from '../api/racqy';
import { racqys } from '../api/api-requests';
import Loader from '../components/Loader';
import useMobileView from '../hooks/useMobileView';
import { hideLoader } from '../utils/domSelector';

export default function RacqyAppUrl({ location, params }) {
  const userLocation = GetLocation();

  const mobileView = useMobileView('max', 768);

  const racqyID = params['*'];

  const [racqy, setRacqyData] = useState({});

  const renderPlayers = () => {
    const { players, maxPlayerCount } = racqy;
    const author = players.find((p) => p.isAuthor);

    const restOfPlayers = players.filter((p) => !p.isAuthor);

    const playerViews = [];
    if (author) {
      const name = author.isThisUser
        ? 'You'
        : `${author.name.first} ${
            author.name.last && `${author.name.last[0]}.`
          }`;

      playerViews.push(
        <div
          className="playerDiv"
          key={author.id}
          onClick={() => navigate(`/u/${author?.username}`)}
        >
          <div className="author-player">
            <img
              style={
                racqy?.profilePic
                  ? {
                      height: '50px',
                      width: '50px',
                      borderRadius: '25px',
                      objectFit: 'cover',
                    }
                  : {
                      height: '20px',
                      width: '40px',
                      objectFit: 'contain',
                    }
              }
              src={author.profilePictureStorageUrl || profileIconPng}
            />
          </div>
          {/* <Image style={[styles.playerViewItemProfile]} source={{ uri: author.profilePictureStorageUrl }} /> */}
          <p
            className="square-box-text"
            style={{ marginTop: !author.profilePictureStorageUrl ? 2 : 10 }}
          >
            {name}
          </p>

          <img
            style={{
              width: 15,
              height: 15,
              objectFit: 'contain',
              tintColor: 'grey',
              position: 'relative',
              top: -20,
              right: 5,
            }}
            src={hostIcon}
          />
        </div>
      );
    }
    let restOfPlayersCount =
      maxPlayerCount == -1 ? restOfPlayers.length : restOfPlayers.length;
    let playersCount =
      maxPlayerCount == -1 ? players.length + 1 : maxPlayerCount;

    if (!racqy?.managerClubInfo) playersCount = playersCount - 1;
    for (let i = 0; i < playersCount; i++) {
      let isMaxCountisInfinity =
        i == players.length - 1 && maxPlayerCount == -1 ? true : false;
      const player = i < restOfPlayersCount ? restOfPlayers[i] : null;
      if (player) {
        const name = player.isThisUser
          ? 'You'
          : `${player.name.first} ${
              player.name.last ? `${player.name.last[0]}.` : ''
            }`;
        playerViews.push(
          <div
            className="playerDiv"
            key={player?.id ? player?.id : `GUEST-${i}`}
            onClick={() => navigate(`/u/${player?.username}`)}
          >
            <div
              className={
                player?.profilePictureStorageUrl
                  ? ''
                  : 'user-with-no-ProfilePic'
              }
            >
              <img
                className="player-image"
                style={{
                  objectFit: player?.profilePictureStorageUrl
                    ? 'cover'
                    : 'contain',
                  height: player?.profilePictureStorageUrl ? '50px' : '20px',
                  width: player?.profilePictureStorageUrl ? '50px' : '40px',
                }}
                src={player?.profilePictureStorageUrl || profileIconPng}
              />
            </div>

            <p className="square-box-text">{player.isGuest ? 'guest' : name}</p>
          </div>
        );
      } else if (!player && isMaxCountisInfinity) {
        playerViews.push(
          <div className="playerDiv" key={i.toString()}>
            <div className="available-slot">
              <img
                className="player-image"
                style={{
                  objectFit: 'contain',
                  height: '25px',
                  width: '20px',
                }}
                src={InfinityImage}
              />
            </div>
          </div>
        );
      } else {
        playerViews.push(
          <div className="playerDiv" key={i.toString()}>
            <div className="available-slot" />
            <p
              style={{
                alignItems: 'flex-start',
                textAlign: 'left',
                fontSize: '12px',
                color: '#0A3133',
                textWrap: 'wrap',
              }}
            >
              Available spot
            </p>
          </div>
        );
      }
    }

    return playerViews;
  };
  const renderDifficulty = (difficulty) => {
    if (difficulty) {
      const levels = [];
      if (difficulty.length > 1) {
        for (let i = difficulty[0]; i <= difficulty[1]; i++) {
          const levelColor = getLevelColor(i);
          levels.push(
            <div
              key={i}
              style={{
                width: 20,
                height: 20,
                backgroundColor: levelColor.bg,
                borderRadius: 10,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                marginLeft: 5,
              }}
            >
              <p style={{ color: levelColor.main, fontSize: 12 }}>{i}</p>
            </div>
          );
        }
      } else {
        const levelColor = getLevelColor(difficulty[0]);
        levels.push(
          <div
            key={difficulty[0]}
            style={{
              width: 20,
              height: 20,
              backgroundColor: levelColor.bg,
              borderRadius: 10,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              marginLeft: 5,
            }}
          >
            <p style={{ color: levelColor.main, fontSize: 12 }}>
              {difficulty[0]}
            </p>
          </div>
        );
      }

      return (
        <div style={{ display: 'flex', flexDirection: 'row', marginLeft: 5 }}>
          {levels}
        </div>
      );
    }

    return null;
  };

  const fetchRacqyDetail = async (id) => {
    try {
      if (Object.keys(userLocation.gpsLocation).length > 0) {
        let res = await client(racqys.fetchRacqy(id, userLocation.gpsLocation));

        if (res?.data?.success) {
          const { racqy } = res?.data;

          const authorPlayer = racqy.players.find(
            (player) => player.id === racqy.authorId
          );

          const authorProfilePic =
            racqy?.managerClubInfo && racqy?.managerClubInfo?.clubImage
              ? racqy?.managerClubInfo?.clubImage
              : racqy?.managerClubInfo && !racqy?.managerClubInfo?.clubImage
              ? clubOutlineIconPng
              : authorPlayer?.profilePictureStorageUrl
              ? authorPlayer?.profilePictureStorageUrl
              : profileIconPng;
          const profilePic =
            racqy?.managerClubInfo && racqy?.managerClubInfo?.clubImage
              ? true
              : authorPlayer?.profilePictureStorageUrl
              ? true
              : false;
          //   const gameType = i18n.t(
          //     `home_screen.${RACQYHELPER.gameTypeToTranslationKey(racqy.gameType)}`
          //   );
          const momentRacqyDate = !racqy?.flexibleDate
            ? moment(racqy?.date)
            : null;
          const weekday = !racqy?.flexibleDate
            ? momentRacqyDate.startOf('day').isoWeekday()
            : null;
          const day = !racqy?.flexibleDate
            ? momentRacqyDate.startOf('day').date()
            : null;
          const month = !racqy?.flexibleDate
            ? momentRacqyDate.startOf('day').month()
            : null;
          const year = !racqy?.flexibleDate
            ? momentRacqyDate.startOf('day').year() != moment().year()
              ? momentRacqyDate.startOf('day').year()
              : ''
            : null;

          const formattedStartTime =
            `0${racqy?.startTime.hour}`.slice(-2) +
            ':' +
            `0${racqy?.startTime.min}`.slice(-2);
          const formattedEndTime =
            `0${racqy?.endTime.hour}`.slice(-2) +
            ':' +
            `0${racqy?.endTime.min}`.slice(-2);

          setRacqyData({
            ...racqy,
            weekday,
            day,
            month,
            year,
            formattedStartTime,
            formattedEndTime,
            authorProfilePic,
            profilePic,
          });

          hideLoader();
        }
      }
    } catch (err) {
      console.log('err', err);
      navigate('/404/');
    }
  };
  const handleDownload = () => {
    navigate('/download');
  };
  useEffect(() => {
    if (userLocation.gpsLocation !== null) {
      fetchRacqyDetail(racqyID);
    }
  }, [userLocation.gpsLocation]);

  const copyToClipboard = () => {
    navigator.clipboard.writeText(racqy.activityCode);
    showSuccessAlert('Copied to clipboard');
  };

  return (
    <Layout skipLoading={true} location={location}>
      <Helmet>
        <title>
          {Object.keys(racqy).length == 0
            ? 'Activity'
            : racqy?.managerClubInfo
            ? `${racqy?.managerClubInfo?.clubName} ${getSportsName(
                racqy.sport
              )} Activity`
            : `${racqy?.authorName?.first}'s ${getSportsName(
                racqy.sport
              )} Activity`}
        </title>
      </Helmet>
      <div style={{ height: 70 }} />

      <div className="main-container">
        <div className="leftDiv">
          <div className="mobileView" style={{ position: 'relative' }}>
            <div style={{ position: 'relative' }}>
              <img
                src={
                  racqy?.customImage
                    ? racqy?.customImage
                    : getSportHeaderImage(racqy?.sport)
                }
                className="racqyCoverImage"
              />
              {racqy.activityCode && (
                <h3 className="top-2 absolute text-white left-3 max-md:visible invisible">
                  Activity code: {racqy.activityCode}
                </h3>
              )}
              <img src={exitIcon} className="exitIcon" />
              <div className={racqy.profilePic ? '' : 'profile-container'}>
                <div
                  className={
                    !racqy.profilePic
                      ? 'authorProfileIcon-empty-wrapper'
                      : 'authorProfileIcon-wrapper'
                  }
                >
                  <img
                    src={racqy.authorProfilePic}
                    className={
                      racqy.profilePic
                        ? 'authorProfileLargeIcon '
                        : 'authorProfileIcon'
                    }
                    style={{
                      borderRadius: racqy?.profilePic ? '40px' : '0px',
                      objectFit: !racqy?.profilePic ? 'contain' : 'cover',
                    }}
                  />
                  {racqy?.profilePic && (
                    <p
                      className="authorProfileIcon-text"
                      style={{
                        marginLeft: racqy?.profilePic ? '10px' : '10px',
                      }}
                    >
                      {racqy.activityTitle ? racqy.activityTitle : ''}
                    </p>
                  )}
                </div>
                {!racqy?.profilePic && (
                  <p
                    className="authorProfileIcon-text"
                    style={{ marginLeft: racqy?.profilePic ? '10px' : '10px' }}
                  >
                    {racqy.activityTitle ? racqy.activityTitle : ''}
                  </p>
                )}
              </div>
            </div>
            <div>
              <div className="square-box-wrapper">
                <div className="square-box">
                  <img className="square-box-image" src={calendarIconPng} />
                </div>
                <p className="square-box-text">
                  {racqy?.recurring
                    ? 'recurring event'
                    : !racqy?.flexibleDate
                    ? `${moment(racqy?.date).format('dddd')} ${
                        racqy?.day ? racqy?.day : ''
                      } ${moment(racqy?.date).format('MMM')}${
                        racqy?.year ?? ''
                      }, ${
                        racqy?.formattedStartTime
                          ? racqy?.formattedStartTime
                          : ''
                      }-${
                        racqy?.formattedEndTime ? racqy?.formattedEndTime : ''
                      }`
                    : 'Flexibelt datum'}
                </p>
              </div>

              <div className="square-box-wrapper">
                <div className="square-box">
                  <img className="square-box-image" src={locationIconPng} />
                </div>
                <p className="square-box-text">
                  {racqy?.locationIsTBD
                    ? 'To be decided'
                    : !racqy?.flexibleLocation
                    ? racqy?.location?.name
                    : 'Flexibel plats'}
                </p>
              </div>

              <div className="square-box-wrapper">
                <div className="square-box">
                  <img className="square-box-image" src={ticketIconPng} />
                </div>
                <div>
                  <p className="square-box-text">
                    {racqy?.court?.isBooked ? 'Tickets booked' : 'No booking'}
                  </p>
                  {racqy?.court?.isBooked &&
                    ![
                      'golf',
                      'basketball',
                      'beach_tennis',
                      'skateboarding',
                      'scooter',
                    ].includes(racqy?.sport) && (
                      <p className="square-box-text">
                        {`'$'${racqy?.court?.totalPrice} ${racqy?.court?.currency}`}
                      </p>
                    )}
                </div>
              </div>

              <div className="square-box-wrapper">
                <div className="square-box">
                  <img className="square-box-image" src={difficultyIconPng} />
                </div>
                <div
                  style={{
                    marginTop: '12px',
                    marginLeft:
                      racqy?.difficulty?.length === 0 ? '10px' : '0px',
                  }}
                >
                  {racqy?.difficulty?.length > 0 ? (
                    renderDifficulty(racqy?.difficulty)
                  ) : (
                    <p className="text-[13px] font-medium text-racqy-text">
                      No difficulty selected
                    </p>
                  )}
                </div>
              </div>
              {racqy.notes && (
                <div>
                  <h4 className="square-box-text" style={{ color: '#209CA2' }}>
                    Notes
                  </h4>
                  <p className="text-xs ml-[10px]">{racqy?.notes}</p>
                </div>
              )}
            </div>

            <div className="playersWrapper">
              {Object.keys(racqy).length > 0 && renderPlayers()}
            </div>
            <div className="px-2 downloadButton-wrapper">
              {mobileView ? (
                <div
                  onClick={handleDownload}
                  className="download-mobile-button"
                >
                  <p style={{ color: 'white', fontWeight: 'bold' }}>
                    Download app to join
                  </p>
                </div>
              ) : racqy.activityCode ? (
                <div
                  className="download-mobile-button"
                  onClick={copyToClipboard}
                >
                  <p style={{ color: 'white', fontWeight: 'bold' }}>
                    Activity code: {racqy.activityCode}
                  </p>
                </div>
              ) : (
                <div
                  onClick={handleDownload}
                  className="download-mobile-button"
                >
                  <p style={{ color: 'white', fontWeight: 'bold' }}>
                    Download app to join
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="pt-[100px] flex items-center">
          <div className="rightDiv">
            <h1 style={{ color: '#0A3133' }}>
              You've been invited to an activity!
            </h1>

            {racqy.activityCode && (
              <h3 className="mt-[20px]">
                Enter activity code in app:{' '}
                <span className="text-xl font-bold">{racqy.activityCode}</span>
              </h3>
            )}

            <div onClick={handleDownload} className="downloadButton">
              <p style={{ color: 'white', fontWeight: 'bold' }}>
                Download app to join
              </p>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}
